/**
 * @file   src\components\Students Details.tsx
 * @brief  This component used as a layout for the content which can be replaced.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { Col, Row, Breadcrumb, Button, Nav, NavDropdown, Table, ProgressBar, Tab, Tabs } from 'react-bootstrap';
import '../../assets/css/ProfileCreation.scss';
import '../../assets/css/StudentsDetails.scss';
import { slide as Filter } from 'react-burger-menu';
import Close from '../../assets/img/Close.svg';
import { useIntlMessages } from '../../utils/helper';
import Input from '../../components/MAInput';
import Select from '../../components/MASelect';
import { FormattedMessage } from 'react-intl';
import '../../assets/css/myprof.scss';
import proImg from '../../assets/img/pro_img.png';
import More from '../../assets/img/More.svg';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { end } from '@popperjs/core';
import Tktstar from '../../assets/img/TicketStar.png';
import Arrowrgt from '../../assets/img/icon-double caret.svg';
import Accordion from 'react-bootstrap/Accordion';
import Sort from '../../assets/img/icon/Sort';
import ViewIcon from '../../assets/img/icon/View';
import UserImg from '../../assets/img/Userimg.jpg';
import icnTask from '../../assets/img/icn-doc.svg';
import icnCalendar from '../../assets/img/icn_calendar_small.svg';
import levIcnBlue from '../../assets/img/level-icn-blue.svg';
import levIcnGrn from '../../assets/img/level-icn-grn.svg';
import levIcnRd from '../../assets/img/level-icn-rd.svg';
import Search from '../../components/MASearch';
import {
  showKudosPoints,
  listKudosPoints,
  addKudosPoints,
  userdetails,
  listSurveysForAppUser,
  listGoalsForAppUser,
  listTaskEventAppUser,
  listKudosLevels,
} from '../../store/actions/userActions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useEffect, useState } from 'react';
import { RootState } from '../../store';
import { MessageToaster } from '../../utils/ToastUtil';
import { BUCKET_URL, DATE_TIME_FORMAT_DISPLAY, DATE_FORMAT, DEFAULT_PAGE_INDEX, PAGE_SIZE } from '../../utils/constants';
import moment from 'moment';
import { ISurveyListRequest, IGoalListRequest, IGoalMapInfo, ISurveyMapInfo, ITaskEventListRequest } from '../../interfaces/UserInterface';
import useDebounce from '../../hooks/useDebounce';
import Pagination from '../../components/Pagination';
import Loader from '../../components/Loader';
import { RoleTypeIds } from '../../utils/enums';
import { getFromLocalStorage, getCurrentOrgDetails } from '../../utils/helper';
import { resetGoalsSurveysTasks, resetUserDetails } from '../../store/slices/userSlice';

const kudosDateOptions = [
  {
    label: 'Last 24 hours',
    value: 'Last_24_hours',
  },
  {
    label: 'Last 48 hours',
    value: 'Last_48_hours',
  },
  {
    label: 'Last 7 days',
    value: 'Last_7_days',
  },
  {
    label: 'Last 30 days',
    value: 'Last_30_days',
  },
  {
    label: 'Last 1 month',
    value: 'Last_1_month',
  },
  {
    label: 'Last 3 months',
    value: 'Last_3_months',
  },
  {
    label: 'Last 6 months',
    value: 'Last_6_month',
  },
  {
    label: 'Last 1 year',
    value: 'Last_1_year',
  },
];

const UserStudentsdetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  // Action dispatch
  const dispatch = useAppDispatch();
  // Message toast object creation
  const toastObj = new MessageToaster();
  // access redux state variables
  const {
    showKudosHistoryApiData,
    showKudosPointsApiLoading,
    showKudosHistoryApiSuccess,
    addKudosApiLoading,
    addKudosApiSuccess,
    addKudosApiResponseMessage,
    userDetail,
    userDetailApiLoading,
    listGoalsApiData,
    listGoalsApiLoading,
    listGoalsApiSuccess,
    listSurveysApiData,
    listSurveysApiSuccess,
    listSurveysApiLoading,
    listTasksEventsApiData,
    listTasksEventsApiSuccess,
    listTasksEventsApiLoading,
    showKudosPointsApiData,
    showKudosPointsApiSuccess,
  } = useAppSelector((state: RootState) => state.user);

  const userData = getFromLocalStorage('MI_USR_DATA');
  const userId = params.userId || '';

  let orgId;
  if (userData.RoleTypeId === RoleTypeIds.ORGANIZATION_ADMIN) {
    orgId = getCurrentOrgDetails().OrganizationID;
  } else {
    orgId = userData.OrganizationId;
  }

  // component state variables
  const [dateOption, setDateOption] = useState<any>(kudosDateOptions[0]);
  const [kudosHistoryApiRequest, setKudosHistoryApiRequest] = useState<any>({
    DateRange: kudosDateOptions[0].value,
    Page: 1,
    UserId: userId,
  });
  const [kudosPoint, setKudosPoint] = useState<string>('0');
  const [currentPageGoal, setCurrentPageGoal] = useState<number>(1);
  const [currentPageSurvey, setCurrentPageSurvey] = useState<number>(1);
  const [currentPageTE, setCurrentPageTE] = useState<number>(1);
  const [listSurveyRequest, setListSurveyRequest] = useState<ISurveyListRequest>({
    OrganizationID: orgId,
    Page: 1,
    PageSize: 0,
    Status: 0,
    UserID: userId,
    Search: '',
  });
  const [listGoalsRequest, setListGoalsRequest] = useState<IGoalListRequest>({
    OrganizationID: orgId,
    Page: 1,
    SearchText: '',
    Status: '',
    UserId: userId,
  });
  const [listTaskEventRequest, setListTaskEventRequest] = useState<ITaskEventListRequest>({
    OrganizationID: orgId,
    Page: 0,
    PageSize: 0,
    Search: '',
    Status: 0,
    UserID: userId,
  });
  const [searchTextGoal, setSearchTextGoal] = useState<string>('');
  const [searchTextSurvey, setSearchTextSurvey] = useState<string>('');
  const [overlay, setOverlay] = useState<boolean>(false);
  // Invoke debounce component on intervel.
  const debouncedSearch = useDebounce(searchTextGoal, 500);
  const debouncedSearchSurvey = useDebounce(searchTextSurvey, 500);
  const NillText = useIntlMessages('Label.Nill');
  const noData = useIntlMessages('Label.Nodata');
  // Intial loading api call
  useEffect(() => {
    dispatch(
      showKudosPoints({
        AppUserID: userId,
      }),
    );
    dispatch(
      listKudosPoints({
        DateRange: 'Last_24_hours',
        Page: 0,
        UserId: userId,
      }),
    );
    dispatch(
      userdetails({
        UserId: userId,
      }),
    );
    dispatch(listKudosLevels({}));
  }, []);
  // api call to kudos history when date option changes
  useEffect(() => {
    dispatch(listKudosPoints(kudosHistoryApiRequest));
  }, [kudosHistoryApiRequest.DateRange]);
  // show add kudos success message and add kudos failure message
  useEffect(() => {
    if (addKudosApiSuccess) {
      toastObj.toastSuccess(addKudosApiResponseMessage);
      dispatch(listKudosPoints(kudosHistoryApiRequest));
      dispatch(
        showKudosPoints({
          AppUserID: userId,
        }),
      );
      setOverlay(false);
      setKudosPoint('0');
    } else if (!addKudosApiSuccess && !addKudosApiLoading && addKudosApiResponseMessage.length > 0) {
      toastObj.toastError(addKudosApiResponseMessage);
    }
  }, [addKudosApiLoading]);
  // call list goals api when list goal params changes
  useEffect(() => {
    dispatch(listGoalsForAppUser(listGoalsRequest));
  }, [listGoalsRequest]);
  // call list surveys api when list survey params changes
  useEffect(() => {
    dispatch(listSurveysForAppUser(listSurveyRequest));
  }, [listSurveyRequest]);
  // call list task and events api when list task and events params changes
  useEffect(() => {
    dispatch(listTaskEventAppUser(listTaskEventRequest));
  }, [listTaskEventRequest]);
  // Set API params on bebounced search goals list.
  useEffect(() => {
    setListGoalsRequest((apiParams: IGoalListRequest) => ({
      ...apiParams,
      SearchText: searchTextGoal,
      Page: DEFAULT_PAGE_INDEX,
    }));
    setCurrentPageGoal(DEFAULT_PAGE_INDEX);
  }, [debouncedSearch]);

  // Set API params on bebounced search survey list.
  useEffect(() => {
    setListSurveyRequest((apiParams: ISurveyListRequest) => ({
      ...apiParams,
      Search: searchTextSurvey,
      Page: DEFAULT_PAGE_INDEX,
    }));
    setCurrentPageSurvey(DEFAULT_PAGE_INDEX);
  }, [debouncedSearchSurvey]);
  // reset data on component unmount
  useEffect(() => {
    return () => {
      dispatch(resetGoalsSurveysTasks());
      dispatch(resetUserDetails());
    };
  }, []);
  // handle kudos date select box change
  const handleKudosDateChange = (option: { label: string; value: string }) => {
    setDateOption(option);
    setKudosHistoryApiRequest((prev: any) => ({
      ...prev,
      DateRange: option.value,
    }));
  };
  // handle kudos point change
  const handleKudosPointChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKudosPoint(event.target.value);
  };
  //  api call to add kudos point to app suer
  const addKudosToStudent = () => {
    setOverlay(false);
    dispatch(
      addKudosPoints({
        KudosPoints: Number(kudosPoint),
        UserID: userId,
      }),
    );
  };
  // handle tabs change
  const handleTabChange = (key: string) => {
    if (key == 'Surveys') {
      setListSurveyRequest((apiParams: ISurveyListRequest) => ({
        ...apiParams,
        Search: '',
        Page: DEFAULT_PAGE_INDEX,
      }));
      setCurrentPageSurvey(DEFAULT_PAGE_INDEX);
    } else if (key == 'Goals') {
      setListGoalsRequest((apiParams: IGoalListRequest) => ({
        ...apiParams,
        SearchText: '',
        Page: DEFAULT_PAGE_INDEX,
      }));
      setCurrentPageGoal(DEFAULT_PAGE_INDEX);
    }
  };
  // create sub categories string
  const getSubCategories = (subCategories: any) => {
    if (subCategories !== null) {
      let finalString = '';
      subCategories.forEach((sub: { classid: number; classname: string }) => (finalString = finalString + sub.classname + ','));
      return finalString;
    }
  };
  // convert status to string
  const getStatusString = () => {
    const status = params.status;
    let statusString: string;
    switch (status) {
      case '1':
        statusString = 'Completed';
        break;
      case '2':
        statusString = 'In Progress';
        break;
      case '3':
        statusString = 'Not started';
        break;
      case '4':
        statusString = 'Overdue';
        break;
      default:
        statusString = '';
        break;
    }
    return statusString;
  };
  // handle pagination change
  const handlePageChange = (pageNo: number, tab: string) => {
    if (tab === 'goals') {
      setListGoalsRequest((apiParams: IGoalListRequest) => ({
        ...apiParams,
        Page: pageNo,
      }));
      setCurrentPageGoal(pageNo);
    } else if (tab === 'survey') {
      setListSurveyRequest((apiParams: ISurveyListRequest) => ({
        ...apiParams,
        Page: pageNo,
      }));
      setCurrentPageSurvey(pageNo);
    } else {
      setListTaskEventRequest((apiParams: ITaskEventListRequest) => ({
        ...apiParams,
        Page: pageNo,
      }));
      setCurrentPageTE(pageNo);
    }
  };
  // show current kudos level badge
  const getCurrentLevel = () => {
    let levelBadge;
    if (showKudosPointsApiData.TotalKudos > 0 && showKudosPointsApiData.TotalKudos < 21) {
      levelBadge = (
        <div className="kud-pnt-bg-bl d-flex">
          <div className="sec-left-lev d-flex">
            <img src={levIcnBlue} alt="" />
            <p className="mt-3 text-white font-xs pl-1">Level 1</p>
          </div>
          <div className="font-xs d-flex align-items-center p-3 text-start text-white">Earn 3 more kudos to get next badge</div>
        </div>
      );
    } else if (showKudosPointsApiData.TotalKudos > 20 && showKudosPointsApiData.TotalKudos < 41) {
      levelBadge = (
        <div className="kud-pnt-bg-bl-g d-flex">
          <div className="sec-left-lev d-flex">
            <img src={levIcnGrn} alt="" />
          </div>
          <div className="font-xs d-flex justify-content-center align-items-center p-3 text-white sec-rgt-lev">Level 1 - Kudos 20 to 40</div>
        </div>
      );
    } else if (showKudosPointsApiData.TotalKudos > 40) {
      levelBadge = (
        <div className="kud-pnt-bg-rd d-flex ">
          <div className="sec-left-lev d-flex">
            <img src={levIcnRd} alt="" />
          </div>
          <div className="font-xs d-flex justify-content-center align-items-center p-3 text-white sec-rgt-lev">Level 3 - Kudos 40 above</div>
        </div>
      );
    }
    return levelBadge;
  };
  const handleSortClick = () => {
    console.log('sort');
  };
  return (
    <>
      <div className="page-title d-flex justify-content-between align-items-center">
        <h3>Students Details</h3>
        <Col md="auto">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item>
              <FormattedMessage id="Hd.Users" />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Student Details</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </div>
      <div className="myprof-cont">
        <Row className="mb-4">
          <Col xl={4} lg={5}>
            <div className="mypro-sec-L ">
              <div className="pro-bg "></div>
              <img src={userDetail && userDetail?.LogoImage ? BUCKET_URL + userDetail?.LogoImage : UserImg} className="prof-wrp" alt="" />
              <div className="mng-pro-det">
                <h6>{userDetail && userDetail?.FullName}</h6>
                <span>{userDetail && userDetail?.RoleName}</span>
                {/* <div className='d-flex justify-content-center m-3'>
                                    <div className='me-2'><img src={Tktstar} alt="" /></div>
                                    <p className='m-0 text-white'>17 Kudos Points</p>
                                    <div className='ms-2'><img src={Arrowrgt} alt="" /></div>
                                </div> */}
                <div className="d-flex justify-content-center bt-sec mt-3">
                  <div className="custom-berger-menu custom-berger-menu-kudos">
                    <Filter
                      width={450}
                      right
                      pageWrapId={'filter-wrapper'}
                      outerContainerId={'outer-container'}
                      customCrossIcon={<img src={Close} />}
                      customBurgerIcon={
                        <div className="d-flex justify-content-center m-3">
                          <div className="me-2">
                            <img src={Tktstar} alt="" />
                          </div>
                          <p className="m-0 text-white">{showKudosPointsApiData && showKudosPointsApiData.TotalKudos && showKudosPointsApiData.TotalKudos} Kudos Points</p>
                          <div className="ms-2">
                            <img src={Arrowrgt} alt="" />
                          </div>
                        </div>
                      }
                    >
                      <div id="filter-wrapper" className="filter-main">
                        <Row>
                          <Col lg={7} md={7}>
                            <h4 className="mb-5 float-start">Kudos Level</h4>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12} md={12}>
                            <div className="kud-pnt-bg-bl d-flex">
                              <div className="sec-left-lev d-flex">
                                <img src={levIcnBlue} alt="" />
                              </div>
                              <div className="font-xs d-flex justify-content-center align-items-center p-3 text-white sec-rgt-lev">Level 1 - Kudos 1 to 20</div>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <h6 className="text-start">Earn 3 more kudos to get Level 2 Badge</h6>
                          <Col lg={12} md={12}>
                            <div className="kud-pnt-bg-bl-g d-flex">
                              <div className="sec-left-lev d-flex">
                                <img src={levIcnGrn} alt="" />
                              </div>
                              <div className="font-xs d-flex justify-content-center align-items-center p-3 text-white sec-rgt-lev">Level 1 - Kudos 20 to 40</div>
                            </div>
                          </Col>
                          <Col lg={12} md={12}>
                            <div className="kud-pnt-bg-rd d-flex mt-3">
                              <div className="sec-left-lev d-flex">
                                <img src={levIcnRd} alt="" />
                              </div>
                              <div className="font-xs d-flex justify-content-center align-items-center p-3 text-white sec-rgt-lev">Level 3 - Kudos 40 above</div>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-4 pb-4">
                          <Col>
                            <Button variant="primary" className="w-100" onClick={() => window.alert('Not Implemented')}>
                              <FormattedMessage id="Button.Submit" />
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Filter>
                  </div>
                </div>
                <div className="d-flex justify-content-center bt-sec mt-3">
                  <div className="custom-berger-menu custom-berger-menu-btn">
                    <Filter
                      width={450}
                      right
                      isOpen={overlay}
                      onOpen={() => setOverlay(true)}
                      onClose={() => setOverlay(false)}
                      pageWrapId={'filter-wrapper'}
                      outerContainerId={'outer-container'}
                      customCrossIcon={<img src={Close} />}
                      customBurgerIcon={<div className="d-flex align-items-center multiple-user text-white m-0">Give Kudos</div>}
                    >
                      <div id="filter-wrapper" className="filter-main">
                        <Row>
                          <Col lg={7} md={7}>
                            <h4 className="mb-5 float-start">Kudos Points</h4>
                          </Col>
                          <Col lg={5} md={5} className="form-sm">
                            <Select
                              options={kudosDateOptions}
                              value={dateOption}
                              placeholder="Select"
                              onChange={(e: any) => handleKudosDateChange(e)}
                              // error="Error"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={8} md={8}>
                            {showKudosPointsApiData && showKudosPointsApiData.TotalKudos && getCurrentLevel()}
                          </Col>
                          <Col lg={4} md={4}>
                            <div className="kud-pnt-bg-gr">
                              <div className="d-flex justify-content-center">
                                <img src={Tktstar} alt="" />
                                <h3>
                                  <b>{showKudosPointsApiData && showKudosPointsApiData.TotalKudos && showKudosPointsApiData.TotalKudos}</b>
                                </h3>
                              </div>
                              <div className="font-sm">Kudos Points</div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <div className="pt-4">
                            <h5 className="text-start">Kudos History</h5>
                            {showKudosHistoryApiSuccess &&
                              showKudosHistoryApiData &&
                              showKudosHistoryApiData.Kudos.map((kudos: any) => (
                                <div className="inn-bg p-3 mb-2">
                                  <Row className="d-flex align-items-center">
                                    <Col lg={9} md={9} className="text-start">
                                      <p className="m-0 font-sm">{kudos.KudosType}</p>
                                      <p className="m-0 text-white">{kudos.GivenFor}</p>
                                    </Col>
                                    <Col lg={3} md={3}>
                                      <div className="pts-txt">{kudos.KudosPoints} pts</div>
                                    </Col>
                                  </Row>
                                </div>
                              ))}
                            <Row className="mt-4">
                              <Col lg={12} className="text-start">
                                <Input
                                  label={useIntlMessages('Points')}
                                  id="Name"
                                  name="Name"
                                  type="text"
                                  placeholder={useIntlMessages('5')}
                                  maxLength={200}
                                  value={kudosPoint}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleKudosPointChange(e)}
                                />
                              </Col>
                            </Row>
                          </div>
                        </Row>
                        <Row className="mt-4 pb-4">
                          <Col>
                            <Button variant="outline-primary" className="w-100" onClick={() => setOverlay(false)}>
                              <FormattedMessage id="Button.Cancel" />
                            </Button>
                          </Col>
                          <Col>
                            <Button variant="primary" className="w-100" onClick={addKudosToStudent}>
                              <FormattedMessage id="Button.Submit" />
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Filter>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xl={8} lg={7}>
            <div className="content-sub h-100 ">
              <div className="content-sub-header d-flex justify-content-between align-items-center">
                <div>
                  <h4>
                    <FormattedMessage id="Hd.PersInfo" />
                  </h4>
                </div>
                <Col xs="auto">
                  <Nav>
                    <NavDropdown align={end} className="icon-btn-more" title={<img src={More} alt="more" />}>
                      <NavDropdown.Item onClick={() => window.alert('Not Implemented')}>
                        <FormattedMessage id="Link.EditProfile" />
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={() => navigate('/changepassword')}>
                        <FormattedMessage id="Link.Change Password" />
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                </Col>
              </div>
              <div className="content-area-padding">
                <Row className="label-with-content mb-3">
                  <Col xl lg={6} md={3} sm={6}>
                    <label>Organization Name</label>
                    <p>{userDetail && userDetail?.OrganizationName ? userDetail?.OrganizationName : 'Nil'}</p>
                  </Col>
                  <Col xl lg={6} md={3} sm={6}>
                    <label>
                      <FormattedMessage id="Label.DateofBirth" />
                    </label>
                    <p>{userDetail && userDetail?.Dob ? moment(userDetail.Dob).local().format(DATE_FORMAT) : 'Nil'}</p>
                  </Col>
                  <Col xl lg={6} md={3} sm={6}>
                    <label>
                      <FormattedMessage id="Label.Gender" />
                    </label>
                    <p>{userDetail && userDetail.Gender ? (userDetail.Gender === 'F' ? 'Female' : userDetail.Gender === 'M' ? 'Male' : 'Nil') : 'Nil'}</p>
                  </Col>
                  <Col xl lg={6} md={3} sm={6}></Col>
                </Row>
                <Row className="label-with-content mb-3">
                  <Col xl lg={6} md={3} sm={6}>
                    <label>
                      <FormattedMessage id="Label.Email" />
                    </label>
                    <p>{userDetail && userDetail?.EmailId ? userDetail?.EmailId : NillText}</p>
                  </Col>
                  <Col xl lg={6} md={3} sm={6}>
                    <label>
                      <FormattedMessage id="Label.PhoneNum" />
                    </label>
                    <p>{userDetail && userDetail?.PhoneNumber ? userDetail?.PhoneNumber : NillText}</p>
                  </Col>
                  <Col xl lg={6} md={3} sm={6}>
                    <label>Date of Joining</label>
                    <p>{userDetail && userDetail?.JoiningDate ? moment(userDetail?.JoiningDate).local().format(DATE_FORMAT) : NillText}</p>
                  </Col>
                  <Col xl lg={6} md={3} sm={6}></Col>
                </Row>
                <Row className="label-with-content mb-3">
                  <Col xl lg={6} md={3} sm={6}>
                    <label>
                      <FormattedMessage id="Label.Address" />
                    </label>
                    <p>{userDetail && userDetail?.Address ? userDetail?.Address : NillText}</p>
                  </Col>
                  <Col xl lg={6} md={3} sm={6}>
                    <label>
                      <FormattedMessage id="Label.City" />
                    </label>
                    <p>{userDetail && userDetail?.CityName ? userDetail?.CityName : NillText}</p>
                  </Col>
                  <Col xl lg={6} md={3} sm={6} className="d-flex">
                    <div>
                      <label>
                        <FormattedMessage id="Label.State" />
                      </label>
                      <p>{userDetail && userDetail?.StateName ? userDetail?.StateName : NillText}</p>
                    </div>
                  </Col>
                  <Col xl lg={6} md={3} sm={6} className="d-flex">
                    <div className="zip-col">
                      <label>
                        <FormattedMessage id="Label.Zip" />
                      </label>
                      <p>{userDetail && userDetail?.ZipName ? userDetail?.ZipName : NillText}</p>
                    </div>
                  </Col>
                </Row>
                <Row className="label-with-content mb-3">
                  <Col xl lg={6} md={3} sm={6}>
                    <label>Highest Education</label>
                    <p>{userDetail && userDetail?.HighestEducation ? userDetail?.HighestEducation : NillText}</p>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        <div className="content-sub mt-2">
          {/* <div className="content-sub-header d-flex justify-content-between align-items-center">
            <h4>Completed Surveys</h4>
          </div> */}
          <div className="content-area-padding">
            <Tabs defaultActiveKey={'Goals'} transition={false} id="noanim-tab-example" className="mb-3" onSelect={(key: any) => handleTabChange(key)}>
              <Tab eventKey="Goals" title={getStatusString() + ' ' + useIntlMessages('Label.Goals')}>
                {/* <div className="d-flex justify-content-end mb-3">
                  <Col lg={3} className="form-sm">
                    <Search
                      onChange={(event: any) => handleSearchChange(event, 'goal')}
                      onKeyDown={(event: any) => handleSearchKeyDown(event, 'goals')}
                      onClick={() => handleSearchClick('goals')}
                      placeholder={useIntlMessages('PH.SearchGoal')}
                      size="sm"
                    />
                  </Col>
                </div> */}
                <Table striped hover responsive>
                  <thead>
                    <tr>
                      <th>
                        <FormattedMessage id="Label.GoalName" />
                        <Link to="" className="ms-1" onClick={handleSortClick}>
                          <Sort />
                        </Link>
                      </th>
                      <th>
                        <FormattedMessage id="Label.Category" />
                        <Link to="" className="ms-1" onClick={handleSortClick}>
                          <Sort />
                        </Link>
                      </th>
                      <th>
                        <FormattedMessage id="Label.SubCategory" />
                        <Link to="" className="ms-1"></Link>
                      </th>
                      <th>
                        <FormattedMessage id="Label.CreatedBy" />
                        <Link to="" className="ms-1" onClick={handleSortClick}>
                          <Sort />
                        </Link>
                      </th>
                      <th>
                        <FormattedMessage id="Label.Status" />
                        <Link to="" className="ms-1"></Link>
                      </th>
                      <th className="w-50px"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {listGoalsApiSuccess &&
                      listGoalsApiData.Goals &&
                      listGoalsApiData.Goals.map((goal: IGoalMapInfo) => (
                        <tr>
                          <td>{goal.GoalName}</td>
                          <td>{goal.CategoryName}</td>
                          <td>{getSubCategories(goal.GoalClasses)}</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <span className="td-user-image">
                                <img src={UserImg} className="w-100" alt="user" />
                              </span>
                              {goal.CreatedBy}
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-items-center multiple-user">
                              <ProgressBar now={goal.CompletedPercentage} />{' '}
                              <span className="ps-2">
                                <label className="td-progressbar">
                                  {goal.CompletedPercentage}% <span className="inprogress">{goal.UserStatus}</span>
                                </label>
                              </span>
                            </div>
                          </td>
                          <td>
                            <Button variant="dark" size="sm" className="icon-btn" onClick={() => navigate(`/goaldetail/${goal.GoalId}`)}>
                              <ViewIcon />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    {listGoalsApiSuccess && listGoalsApiData.Goals === null && (
                      <tr>
                        <td colSpan={8}>
                          <div className="content-sub content-area-padding border-top text-center">
                            <h6>{noData}</h6>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                {listGoalsApiData && listGoalsApiData.Goals && listGoalsApiData.Goals.length > 0 && listGoalsApiData.TotalCount > PAGE_SIZE && (
                  <Pagination
                    pageSize={PAGE_SIZE}
                    totalitems={listGoalsApiData.TotalCount}
                    pageLimit={PAGE_SIZE}
                    setCurrentPage={(page: number) => handlePageChange(page, 'goals')}
                    currentPage={currentPageGoal - 1}
                    prevPage={-1}
                    itemsDisplayed={listGoalsApiData?.Goals?.length}
                  />
                )}
              </Tab>
              <Tab eventKey="Surveys" title={getStatusString() + ' ' + useIntlMessages('Label.Surveys')}>
                {/* <div className="d-flex justify-content-end mb-3">
                  <Col lg={3} className="form-sm">
                    <Search
                      onChange={(event: any) => handleSearchChange(event, 'goals')}
                      onKeyDown={(event: any) => handleSearchKeyDown(event, 'survey')}
                      onClick={() => handleSearchClick('survey')}
                      placeholder={useIntlMessages('PH.SearchSurvey')}
                      size="sm"
                    />
                  </Col>
                </div> */}
                <Table striped hover responsive>
                  <thead>
                    <tr>
                      <th className="fix-width">
                        <FormattedMessage id="Label.SurveyName" />
                        <Link to="" className="ms-1" onClick={handleSortClick}>
                          <Sort />
                        </Link>
                      </th>
                      <th>
                        <FormattedMessage id="Label.Category" />
                        <Link to="" className="ms-1" onClick={handleSortClick}>
                          <Sort />
                        </Link>
                      </th>
                      <th>
                        <FormattedMessage id="Label.SubCategories" />
                        <Link to="" className="ms-1"></Link>
                      </th>
                      <th>
                        <FormattedMessage id="Label.CreatedBy" />
                        <Link to="" className="ms-1" onClick={handleSortClick}>
                          <Sort />
                        </Link>
                      </th>
                      <th>
                        <FormattedMessage id="Label.Status" />
                        <Link to="" className="ms-1"></Link>
                      </th>
                      <th className="w-50px"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {listSurveysApiSuccess &&
                      listSurveysApiData.SurveyList &&
                      listSurveysApiData.SurveyList.map((survey: ISurveyMapInfo) => (
                        <tr>
                          <td>{survey.SurveyName}</td>
                          <td>{survey.CategoryName}</td>
                          <td>{getSubCategories(survey.SurveyClasses)}</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <span className="td-user-image">
                                <img src={UserImg} className="w-100" alt="user" />
                              </span>
                              {survey.CreatedBy}
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-items-center multiple-user">
                              <ProgressBar now={60} />{' '}
                              <span className="ps-2">
                                <label className="td-progressbar">
                                  <span className="inprogress">{survey.Status}</span>
                                </label>
                              </span>
                            </div>
                          </td>
                          <td>
                            <Button variant="dark" size="sm" className="icon-btn" onClick={() => navigate(`/surveydetails/${survey.SurveyID}`)}>
                              <ViewIcon />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    {listSurveysApiSuccess && listSurveysApiData.SurveyList === null && (
                      <tr>
                        <td colSpan={8}>
                          <div className="content-sub content-area-padding border-top text-center">
                            <h6>{noData}</h6>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                {listSurveysApiData && listSurveysApiData.SurveyList && listSurveysApiData.SurveyList.length > 0 && listSurveysApiData.TotalCount > PAGE_SIZE && (
                  <Pagination
                    pageSize={PAGE_SIZE}
                    totalitems={listSurveysApiData.TotalCount}
                    pageLimit={PAGE_SIZE}
                    setCurrentPage={(page: number) => handlePageChange(page, 'survey')}
                    currentPage={currentPageSurvey - 1}
                    prevPage={-1}
                    itemsDisplayed={listSurveysApiData.SurveyList.length}
                  />
                )}
              </Tab>
              <Tab eventKey="taskevent" title={getStatusString() + ' ' + useIntlMessages('Label.TaskEvents')}>
                <div className="content-area-padding">
                  <div className="bdr-content-area  p-3 ">
                    {listTasksEventsApiSuccess &&
                      listTasksEventsApiData.TaskAndEventList &&
                      listTasksEventsApiData.TaskAndEventList.map((taskevent: any) => (
                        <div className="d-flex justify-content-center inn-bg p-3 mb-3">
                          <Col lg={10} md={10} className="d-flex align-items-center">
                            <img src={taskevent.TypeID === 1 ? icnTask : icnCalendar} alt="" className="pe-2" />
                            <p className="m-0 text-white">{taskevent.Name}</p>
                          </Col>
                        </div>
                      ))}
                    {listTasksEventsApiSuccess && listTasksEventsApiData.TaskAndEventList === null && (
                      <tr>
                        <td colSpan={8}>
                          <div className="content-sub content-area-padding border-top text-center">
                            <h6>{noData}</h6>
                          </div>
                        </td>
                      </tr>
                    )}
                  </div>
                </div>
                {listTasksEventsApiSuccess &&
                  listTasksEventsApiData.TaskAndEventList &&
                  listTasksEventsApiData.TaskAndEventList.length > 0 &&
                  listTasksEventsApiData.TotalCount > PAGE_SIZE && (
                    <Pagination
                      pageSize={PAGE_SIZE}
                      totalitems={listTasksEventsApiData.TotalCount}
                      pageLimit={PAGE_SIZE}
                      setCurrentPage={(page: number) => handlePageChange(page, 'task')}
                      currentPage={currentPageTE - 1}
                      prevPage={-1}
                      itemsDisplayed={listTasksEventsApiData.TaskAndEventList.length}
                    />
                  )}
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
      {(listGoalsApiLoading || listSurveysApiLoading || listTasksEventsApiLoading || addKudosApiLoading) && <Loader />}
    </>
  );
};
export default UserStudentsdetails;
