/**
 * @file   src\store\actions\profileActions.ts
 * @brief  This file is responsible for creating asynchronous profile based api call.
 * @date   MAY, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../config/axiosConfig';
import { ProfileApis } from '../../utils/apiUrls';
import axios2 from 'axios';
import {
  ISendPhoneOtpRequest,
  ICreateAccountRequest,
  IProfileForm,
  IVerifyOtpRequest,
  IPlanListRequest,
  ISubscriptionPaymentRequest,
  IAddSubscription,
} from '../../interfaces/ProfileInterface';
const REACT_APP_API_KEY = '$2a$12$EASChu/o0cpX6JUn.SFvnO/bWIZKwnTswABAJD7erjWf5btMRBjlq';
// Api call to create teacher profile
export const createTeacherProfile = createAsyncThunk('/createprofile', async (request: IProfileForm, { rejectWithValue }) => {
  try {
    const { data } = await axios.put(ProfileApis.CREATE_PROFILE, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to create user account
export const createUserAccount = createAsyncThunk('/createaccount', async (request: ICreateAccountRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios2.post(`${ProfileApis.CREATE_ACCOUNT}`, request, {
      headers: {
        'X-API-Key': REACT_APP_API_KEY,
      },
    });
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to verify otp code
export const verifyOtpCode = createAsyncThunk('/verifyOtpCode', async (request: IVerifyOtpRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${ProfileApis.VERIFY_OTP}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to validate token and get user data
export const validateToken = createAsyncThunk('/validateToken', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios2.post(`${ProfileApis.VALIDATE_TOKEN}`, request, {
      headers: {
        'X-API-Key': REACT_APP_API_KEY,
      },
    });
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to get list of avatars
export const getAvatarsList = createAsyncThunk('/getAvatarsList', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(ProfileApis.GET_AVATARS_LIST);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});

// Api call to send otp to phone number
export const sendOtpToPhone = createAsyncThunk('/sendPhoneOtp', async (request: ISendPhoneOtpRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(ProfileApis.SEND_PHONE_OTP, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to send otp to phone number
export const listSubscriptionPlans = createAsyncThunk('/subscriptonPlansList', async (request: IPlanListRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(ProfileApis.SUBSCRIPTION_PLANS_BYORGID, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to subscription payment
export const addSubScriptionPayment = createAsyncThunk('/subscriptonPayment', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(ProfileApis.SUBSCRIPTION_PAYMENT, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to get list of payment cards
export const getCardsList = createAsyncThunk('/listcards', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(ProfileApis.LIST_PAYMENT_CARDS, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to get billing history
export const getBillingHistory = createAsyncThunk('/getbillinghistory', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(ProfileApis.LIST_BILLING_HISTORY, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to cancel subscription
export const cancelSubscriptionPlan = createAsyncThunk('/cancelsubscription', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(ProfileApis.CANCEL_SUBSCRIPTION, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to add payment method
export const addPaymentMethod = createAsyncThunk('/addpaymentmethod', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(ProfileApis.ADD_PAYMENT_METHOD, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to delete payment method
export const deletePaymentMethod = createAsyncThunk('/deletepaymentmethod', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(ProfileApis.DELETE_PAYMENT_METHOD, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
