/**
 * @file   src\containers\organizations\ReviewProof.tsx
 * @brief  Review Proof page.
 * @date   March, 2024
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useEffect, useState } from 'react';
import '../../assets/css/Home.scss';
import { FormattedMessage } from 'react-intl';
import { Col, Row, Breadcrumb, Button } from 'react-bootstrap';
import Select from '../../components/MASelect';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import UserImg from '../../assets/img/Userimg.jpg';
import RightIcon from '../../assets/img/icon/RightArrow';
import ReactPlayer from 'react-player';
import ReactAudioPlayer from 'react-audio-player';
import { listProofs } from '../../store/actions/userActions';
import { RootState } from '../../store';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { BUCKET_URL, PAGE_SIZE_REVIEW_PROOF } from '../../utils/constants';
import Pagination from '../../components/Pagination';
import Loader from '../../components/Loader';
import { IListReviewProofsRequest } from '../../interfaces/UserInterface';
import { ReviewProofSort, ReviewProofStatus } from '../../utils/enums';
import { useParams } from 'react-router-dom';
import { resetReviewProofs } from '../../store/slices/userSlice';

const showBy = [
  { label: 'Show by Name', value: ReviewProofSort.STUDENT_NAME },
  { label: 'Show by Task', value: ReviewProofSort.TASK },
];
const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const ReviewProof = () => {
  // Action dispatch object creation
  const dispatch = useAppDispatch();
  // Route params object
  const params = useParams();
  // access redux state variables
  const { listProofsApiData, listProofsApiLoading, listProofsApiSuccess } = useAppSelector((state: RootState) => state.user);
  // Component state variables
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [apiParams, setApiParams] = useState<IListReviewProofsRequest>({
    OrganizationID: '3400131449221808471',
    Page: 1,
    PageSize: PAGE_SIZE_REVIEW_PROOF,
    PageSort: ReviewProofSort.STUDENT_NAME,
    Status: params.status !== undefined ? Number(params.status) : 0,
  });
  const [sortOption, setSortOption] = useState<any>(showBy[0]);

  // call list proofs api on intial laoding ,page change , sort change
  useEffect(() => {
    dispatch(listProofs(apiParams));
  }, [apiParams.Page, apiParams.PageSort]);
  // reset list review proofs on unmount
  useEffect(() => {
    return () => {
      dispatch(resetReviewProofs());
    };
  }, []);

  // handle pagination change
  const changePage = (pageNo: number) => {
    setApiParams((prev: any) => ({
      ...prev,
      Page: pageNo,
    }));
    setCurrentPage(pageNo);
  };
  // create review proof status string
  const getProofStatusString = () => {
    const status = params.status || '0';
    let statusString;
    if (Number(status) === ReviewProofStatus.COMPLETED) {
      statusString = 'Competion';
    } else {
      statusString = 'In Progress';
    }
    return statusString;
  };
  return (
    <>
      {listProofsApiLoading && <Loader />}
      <div className="page-title d-flex justify-content-between align-items-center">
        <h3>Review Proof</h3>
        <Col md="auto">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item>
              <FormattedMessage id="Hd.Dashboard" />
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <FormattedMessage id="Hd.Overview" />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Review Proof</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        {/* <Col xl={2} lg={4} md={4} className='form-sm'>
          <Select
            label={useIntlMessages("Label.OrganizationType")}
            options={organization}
            value={organizationValue}
            placeholder="Select"
            onChange={() => console.log('todo')}
          // error="Error"
          />
        </Col> */}
      </div>
      <div className="page-title d-flex justify-content-between align-items-center">
        <h5 className="mb-0">{getProofStatusString()} Proof</h5>
        <Col xl={2} lg={3} md={4} className="form-sm">
          <Select
            options={showBy}
            placeholder="Select"
            onChange={(e: any) => {
              setSortOption(e);
              setApiParams((prev: IListReviewProofsRequest) => ({
                ...prev,
                PageSort: e.value,
              }));
            }}
            value={sortOption}
          />
        </Col>
      </div>
      {listProofsApiSuccess &&
        apiParams.PageSort === ReviewProofSort.STUDENT_NAME &&
        listProofsApiData.ProofList &&
        listProofsApiData.ProofList.map((proof: any) => (
          <div className="content-sub">
            <div className="content-sub-header d-flex justify-content-between align-items-center">
              <div>
                <div className="d-flex align-items-center userthumnail">
                  <span className="td-user-image">
                    <img src={BUCKET_URL + proof.LogoImage} className="w-100" alt="user" />
                  </span>
                  <label>
                    {proof.Name}
                    <span className="d-block font-xs">{proof.OrganizationName}</span>
                  </label>
                </div>
              </div>
              <Col sm="auto">
                <Button variant="dark" size="sm" className="icon-btn">
                  <RightIcon />
                </Button>
              </Col>
            </div>
            <div className="content-area-padding">
              <Row>
                {proof.Proofs.map((attachment: any) => (
                  <Col xl={6}>
                    <div className="content-sub content-sub-secondary">
                      <div className="content-sub-header d-flex justify-content-between align-items-center">
                        <div>
                          <div className="d-flex align-items-center userthumnail">
                            <label>
                              {attachment.mastername}
                              <span className="d-block font-xs">Jan 20, 2022</span>
                            </label>
                          </div>
                        </div>
                        <Col sm="auto">
                          <Button variant="dark" size="sm" className="icon-btn">
                            <RightIcon />
                          </Button>
                        </Col>
                      </div>
                      <div className="content-area-padding">
                        <h5 className="text-center">Attachments ({attachment.proof_details.length})</h5>
                        <div className="reviewproof-main">
                          <Slider {...settings}>
                            {attachment.proof_details.map((details: any) => {
                              if (details.filetype === 'Audio') {
                                return (
                                  <div>
                                    <ReactAudioPlayer src={BUCKET_URL + details.proofpath} autoPlay={false} controls />
                                    <p>filename_name.mp4</p>
                                  </div>
                                );
                              } else if (details.filetype === 'Video') {
                                return (
                                  <div>
                                    <ReactPlayer url={BUCKET_URL + details.proofpath} controls width="100%" />
                                    <p>filename_name.mp4</p>
                                  </div>
                                );
                              } else {
                                return (
                                  <div>
                                    <img src={BUCKET_URL + details.proofpath} height="400px" width="400px" />
                                  </div>
                                );
                              }
                            })}
                          </Slider>
                        </div>
                        <Row className="mt-2">
                          <Col>
                            <Button variant="outline-primary" className="w-100">
                              Add Message
                            </Button>
                          </Col>
                          <Col>
                            <Button variant="outline-primary" className="w-100">
                              Reject
                            </Button>
                          </Col>
                          <Col>
                            <Button variant="primary" className="w-100">
                              Approve
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        ))}
      {listProofsApiSuccess &&
        apiParams.PageSort === ReviewProofSort.TASK &&
        listProofsApiData.ProofList &&
        listProofsApiData.ProofList.map((proof: any) => (
          <div className="content-sub">
            <div className="content-sub-header d-flex justify-content-between align-items-center">
              <div>
                <div className="d-flex align-items-center userthumnail">
                  <label>
                    {proof.Name}
                    <span className="d-block font-xs">Jan 20 2024</span>
                  </label>
                </div>
              </div>
              <Col sm="auto">
                <Button variant="dark" size="sm" className="icon-btn">
                  <RightIcon />
                </Button>
              </Col>
            </div>
            <div className="content-area-padding">
              <Row>
                {proof.Proofs.map((attachment: any) => (
                  <Col xl={6}>
                    <div className="content-sub content-sub-secondary">
                      <div className="content-sub-header d-flex justify-content-between align-items-center">
                        <div>
                          <div className="d-flex align-items-center userthumnail">
                            <span className="td-user-image">
                              <img src={BUCKET_URL + attachment.logoimage} className="w-100" alt="user" />
                            </span>
                            <label>
                              {attachment.name}
                              <span className="d-block font-xs">{attachment.organizationname}</span>
                            </label>
                          </div>
                        </div>
                        <Col sm="auto">
                          <Button variant="dark" size="sm" className="icon-btn">
                            <RightIcon />
                          </Button>
                        </Col>
                      </div>
                      <div className="content-area-padding">
                        <h5 className="text-center">Attachments ({attachment.proof_details.length})</h5>
                        <div className="reviewproof-main">
                          <Slider {...settings}>
                            {attachment.proof_details.map((details: any) => {
                              if (details.filetype === 'Audio') {
                                return (
                                  <div>
                                    <ReactAudioPlayer src={BUCKET_URL + details.proofpath} autoPlay={false} controls />
                                    <p>filename_name.mp4</p>
                                  </div>
                                );
                              } else if (details.filetype === 'Video') {
                                return (
                                  <div>
                                    <ReactPlayer url={BUCKET_URL + details.proofpath} controls width="100%" />
                                    <p>filename_name.mp4</p>
                                  </div>
                                );
                              } else {
                                return (
                                  <div>
                                    <img src={BUCKET_URL + details.proofpath} height="400px" width="400px" />
                                  </div>
                                );
                              }
                            })}
                          </Slider>
                        </div>
                        <Row className="mt-2">
                          <Col>
                            <Button variant="outline-primary" className="w-100">
                              Add Message
                            </Button>
                          </Col>
                          <Col>
                            <Button variant="outline-primary" className="w-100">
                              Reject
                            </Button>
                          </Col>
                          <Col>
                            <Button variant="primary" className="w-100">
                              Approve
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        ))}
      {listProofsApiData && listProofsApiData.ProofList && listProofsApiData.ProofList.length > 0 && listProofsApiData.TotalCount > PAGE_SIZE_REVIEW_PROOF && (
        <Pagination
          pageSize={PAGE_SIZE_REVIEW_PROOF}
          totalitems={listProofsApiData.TotalCount}
          pageLimit={PAGE_SIZE_REVIEW_PROOF}
          setCurrentPage={(page: number) => changePage(page)}
          currentPage={currentPage - 1}
          prevPage={-1}
          itemsDisplayed={listProofsApiData.ProofList.length}
        />
      )}
    </>
  );
};
export default ReviewProof;
