/**
 * @file   src\store\slices\CompetencySlice.ts
 * @brief  This file is responsible for creating Competency based slices to call actions and state management.
 * @date   JAN, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import { createSlice } from '@reduxjs/toolkit';
import { addCompetency, getCompetenciesList, viewCompetency, updateCompetency, getTopCompetenciesList,deleteCompetency,listCompetencyGroup } from '../actions/competencyActions';
import { ICompetencySlice } from '../../interfaces/CompetencyInterface';

// Initial state of Competency slice
const CompetencyDefaultState: ICompetencySlice = {
  addCompetencyApiData: {},
  addCompetencyApiLoading: false,
  addCompetencyApiResponseCode: 0,
  addCompetencyApiResponseMessage: '',
  addCompetencyApiSuccess: false,
  competenciesListData: [],
  competenciesListApiResponseCode: 0,
  competenciesListApiLoading: false,
  competenciesListApiSuccess: false,
  competenciesListApiResponseMessage: '',
  competencyDetail: null,
  competencyViewApiLoading: false,
  competencyViewApiSuccess: false,
  competencyViewApiResponseCode: 0,
  competencyViewApiResponseMessage: '',
  competencyUpdateApiData: {},
  competencyUpdateApiLoading: false,
  competencyUpdateApiSuccess: false,
  competencyUpdateApiResponseCode: 0,
  competencyUpdateApiResponseMessage: '',
  competenciesTopListData: [],
  competenciesTopListApiResponseCode: 0,
  competenciesTopListApiLoading: false,
  competenciesTopListApiSuccess: false,
  competenciesTopListApiResponseMessage: '',
  deleteCompetencyApiData: {},
  deleteCompetencyApiLoading: false,
  deleteCompetencyApiResponseCode: 0,
  deleteCompetencyApiResponseMessage: '',
  deleteCompetencyApiSuccess: false,
  competencyGroupData: {},
  competencyGroupApiLoading: false,
  competencyGroupApiResponseCode: 0,
  competencyGroupApiResponseMessage: '',
  competencyGroupApiSuccess: false,
};

// Competency slice
const CompetencySlice = createSlice({
  name: 'Competency',
  initialState: CompetencyDefaultState,
  reducers: {
    resetCompetencyAdd: (state) => {
      state.addCompetencyApiData = {};
      state.addCompetencyApiLoading = false;
      state.addCompetencyApiResponseCode = 0;
      state.addCompetencyApiResponseMessage = '';
      state.addCompetencyApiSuccess = false;
    },
    resetCompetenciesList: (state) => {
      state.competenciesListData = {};
      state.competenciesListApiLoading = false;
      state.competenciesListApiResponseCode = 0;
      state.competenciesListApiResponseMessage = '';
      state.competenciesListApiSuccess = false;
    },
    resetCompetenciesView: (state) => {
      state.competencyDetail = {};
      state.competencyViewApiLoading = false;
      state.competencyViewApiResponseCode = 0;
      state.competencyViewApiResponseMessage = '';
      state.competencyViewApiSuccess = false;
    },
    resetCompetenciesUpdate: (state) => {
      state.competencyUpdateApiData = {};
      state.competencyUpdateApiLoading = false;
      state.competencyUpdateApiSuccess = false;
      state.competencyUpdateApiResponseCode = 0;
      state.competencyUpdateApiResponseMessage = '';
    },
    resetDeleteCompetency: (state) => {
      state.deleteCompetencyApiData = {};
      state.deleteCompetencyApiResponseCode = 0;
      state.deleteCompetencyApiResponseMessage = '';
      state.deleteCompetencyApiLoading = false;
      state.deleteCompetencyApiSuccess = false;
    },
    resetTopCompetenciesList: (state) => {
      state.competenciesTopListData = {};
      state.competenciesTopListApiLoading = false;
      state.competenciesTopListApiResponseCode = 0;
      state.competenciesTopListApiResponseMessage = '';
      state.competenciesTopListApiSuccess = false;
    },
  },
  extraReducers(builder) {
    // Competency add  api starts
    builder.addCase(addCompetency.pending, (state) => {
      state.addCompetencyApiLoading = true;
      state.addCompetencyApiSuccess = false;
      state.addCompetencyApiResponseCode = 0;
    });
    // Competency add api success
    builder.addCase(addCompetency.fulfilled, (state, action) => {
      state.addCompetencyApiLoading = false;
      state.addCompetencyApiSuccess = true;
      state.addCompetencyApiResponseCode = action.payload.ResponseCode;
      state.addCompetencyApiData = action.payload.ResponseData;
      state.addCompetencyApiResponseMessage = action.payload.ResponseMessage;
    });
    // Competency add api failure
    builder.addCase(addCompetency.rejected, (state, action: any) => {
      state.addCompetencyApiLoading = false;
      state.addCompetencyApiSuccess = false;
      state.addCompetencyApiResponseCode = action.payload.ResponseCode;
      state.addCompetencyApiResponseMessage = action.payload.ResponseMessage;
    });

    // Competency list  api starts
    builder.addCase(getCompetenciesList.pending, (state) => {
      state.competenciesListApiLoading = true;
      state.competenciesListApiSuccess = false;
      state.competenciesListApiResponseCode = 0;
    });
    // Competency list api success
    builder.addCase(getCompetenciesList.fulfilled, (state, action) => {
      state.competenciesListApiLoading = false;
      state.competenciesListApiSuccess = true;
      state.competenciesListApiResponseCode = action.payload.ResponseCode;
      state.competenciesListData = action.payload.ResponseData;
      state.competenciesListApiResponseMessage = action.payload.ResponseMessage;
    });
    // Competency list api failure
    builder.addCase(getCompetenciesList.rejected, (state, action: any) => {
      state.competenciesListApiLoading = false;
      state.competenciesListApiSuccess = false;
      state.competenciesListApiResponseCode = action.payload.ResponseCode;
      state.competenciesListApiResponseMessage = action.payload.ResponseMessage;
    });

    // competency view api starts
    builder.addCase(viewCompetency.pending, (state) => {
      state.competencyViewApiLoading = true;
      state.competencyViewApiSuccess = false;
      state.competencyViewApiResponseCode = 0;
    });
    // competency view api success
    builder.addCase(viewCompetency.fulfilled, (state, action) => {
      state.competencyViewApiLoading = false;
      state.competencyViewApiSuccess = true;
      state.competencyViewApiResponseCode = action.payload.ResponseCode;
      state.competencyDetail = action.payload.ResponseData;
    });
    // competency view api failure
    builder.addCase(viewCompetency.rejected, (state, action: any) => {
      state.competencyViewApiLoading = false;
      state.competencyViewApiSuccess = false;
      state.competencyViewApiResponseCode = action.payload.ResponseCode;
      state.competencyViewApiResponseMessage = action.payload.ResponseMessage;
    });
    // competency update api starts
    builder.addCase(updateCompetency.pending, (state) => {
      state.competencyUpdateApiLoading = true;
      state.competencyUpdateApiSuccess = false;
      state.competencyUpdateApiResponseCode = 0;
    });
    // competencye update api success
    builder.addCase(updateCompetency.fulfilled, (state, action) => {
      state.competencyUpdateApiLoading = false;
      state.competencyUpdateApiSuccess = true;
      state.competencyUpdateApiResponseCode = action.payload.ResponseCode;
      state.competencyUpdateApiResponseMessage = action.payload.ResponseMessage;
    });
    // competency update api failure
    builder.addCase(updateCompetency.rejected, (state, action: any) => {
      state.competencyUpdateApiLoading = false;
      state.competencyUpdateApiSuccess = false;
      state.competencyUpdateApiResponseCode = action.payload.ResponseCode;
      state.competencyUpdateApiResponseMessage = action.payload.ResponseMessage;
    });

    // Top Competency list  api starts
    builder.addCase(getTopCompetenciesList.pending, (state) => {
      state.competenciesTopListApiLoading = true;
      state.competenciesTopListApiSuccess = false;
      state.competenciesTopListApiResponseCode = 0;
    });
    // Top Competency list api success
    builder.addCase(getTopCompetenciesList.fulfilled, (state, action) => {
      state.competenciesTopListApiLoading = false;
      state.competenciesTopListApiSuccess = true;
      state.competenciesTopListApiResponseCode = action.payload.ResponseCode;
      state.competenciesTopListData = action.payload.ResponseData;
      state.competenciesTopListApiResponseMessage = action.payload.ResponseMessage;
    });
    // Top Competency list api failure
    builder.addCase(getTopCompetenciesList.rejected, (state, action: any) => {
      state.competenciesTopListApiLoading = false;
      state.competenciesTopListApiSuccess = false;
      state.competenciesTopListApiResponseCode = action.payload.ResponseCode;
      state.competenciesTopListApiResponseMessage = action.payload.ResponseMessage;
    });

    // Delete Competency   api starts
    builder.addCase(deleteCompetency.pending, (state) => {
      state.deleteCompetencyApiLoading = true;
      state.deleteCompetencyApiSuccess = false;
      state.deleteCompetencyApiResponseCode = 0;
    });
    // Delete Competency  api success
    builder.addCase(deleteCompetency.fulfilled, (state, action) => {
      state.deleteCompetencyApiLoading = false;
      state.deleteCompetencyApiSuccess = true;
      state.deleteCompetencyApiResponseCode = action.payload.ResponseCode;
      state.deleteCompetencyApiData = action.payload.ResponseData;
      state.deleteCompetencyApiResponseMessage = action.payload.ResponseMessage;
    });
    // Delete Competency  api failure
    builder.addCase(deleteCompetency.rejected, (state, action: any) => {
      state.deleteCompetencyApiLoading = false;
      state.deleteCompetencyApiSuccess = false;
      state.deleteCompetencyApiResponseCode = action.payload.ResponseCode;
      state.deleteCompetencyApiResponseMessage = action.payload.ResponseMessage;
    });

     //  Competency  group list api starts
     builder.addCase(listCompetencyGroup.pending, (state) => {
      state.competencyGroupApiLoading = true;
      state.competencyGroupApiSuccess = false;
      state.competencyGroupApiResponseCode = 0;
    });
    //  Competency group list api success
    builder.addCase(listCompetencyGroup.fulfilled, (state, action) => {
      state.competencyGroupApiLoading = false;
      state.competencyGroupApiSuccess = true;
      state.competencyGroupApiResponseCode = action.payload.ResponseCode;
      state.competencyGroupData = action.payload.ResponseData;
      state.competencyGroupApiResponseMessage = action.payload.ResponseMessage;
    });
    //  Competency group list api failure
    builder.addCase(listCompetencyGroup.rejected, (state, action: any) => {
      state.competencyGroupApiLoading = false;
      state.competencyGroupApiSuccess = false;
      state.competencyGroupApiResponseCode = action.payload.ResponseCode;
      state.competencyGroupApiResponseMessage = action.payload.ResponseMessage;
    });
  },
});
// Export actions
export const { resetCompetencyAdd, resetCompetenciesList, resetCompetenciesUpdate, resetCompetenciesView,resetDeleteCompetency,resetTopCompetenciesList } = CompetencySlice.actions;
// Export reducer.
export default CompetencySlice.reducer;
