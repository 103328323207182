import React from 'react';
import { Button, Col, Table } from 'react-bootstrap';
import Search from './MASearch';
import Pagination from './Pagination';
import { useIntlMessages } from '../utils/helper';
import { FormattedMessage } from 'react-intl';
import Sort from '../assets/img/icon/Sort';
import { Link } from 'react-router-dom';
import ViewIcon from '../assets/img/icon/View';
import { DATE_FORMAT, PAGE_SIZE } from '../utils/constants';
import UserImg from '../assets/img/Userimg.jpg';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { OverviewListSortField } from '../utils/enums';

interface OverviewListUsersProps {
  listUsersWithToDoApiData: any;
  currentPage: number;
  setCurrentPage: (arg: number) => void;
  status: number;
  handleSearchChange: (arg: any) => void;
  handleSearchKeyDown: (arg: any) => void;
  handleSearchClick: (arg: any) => void;
  changeSortField: (arg: any) => void;
}

const RadioGroup: React.FC<OverviewListUsersProps> = ({
  listUsersWithToDoApiData,
  currentPage,
  setCurrentPage, status,
  handleSearchChange,
  handleSearchKeyDown,
  handleSearchClick,
  changeSortField,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="d-flex justify-content-end mb-3">
        <Col lg={3} className="form-sm">
          <Search onChange={handleSearchChange} onKeyDown={handleSearchKeyDown} onClick={handleSearchClick} placeholder={useIntlMessages('PH.SearchName')} size="sm" />
        </Col>
      </div>
      <Table striped hover responsive>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="Label.Name" />
              <Link to="" className="ms-1" onClick={() => changeSortField(OverviewListSortField.NAME)}>
                <Sort />
              </Link>
            </th>
            <th>
              <FormattedMessage id="Label.Category" />
            </th>
            <th>
              <FormattedMessage id="Label.SubCategories" />
            </th>
            <th>
              <FormattedMessage id="Label.DateofJoining" />
              <Link to="" className="ms-1" onClick={() => changeSortField(OverviewListSortField.JOINING_DATE)}>
                <Sort />
              </Link>
            </th>
            <th className="w-50px"></th>
          </tr>
        </thead>

        <tbody>
          {listUsersWithToDoApiData &&
            listUsersWithToDoApiData.Users &&
            listUsersWithToDoApiData.Users.length > 0 &&
            listUsersWithToDoApiData.Users.map((user: any) => (
              <tr>
                <td>
                  {' '}
                  <div className="d-flex align-items-center">
                    <span className="td-user-image">
                      <img src={UserImg} className="w-100" alt="user" />
                    </span>
                    <label>{user.Name}</label>
                  </div>
                </td>
                <td>
                  {user.Categories?.map((category: any, index: number) => (
                    <div key={index}>{category.categoryname}</div>
                  ))}
                </td>
                <td>
                  {user.SubCategories?.map((subCategory: any, index: number) => (
                    <div key={index}>
                      {subCategory.classname}
                      {index !== user.SubCategories?.length - 1 && ', '}
                    </div>
                  ))}
                </td>
                <td>{moment(user.JoiningDate).local().format(DATE_FORMAT)}</td>
                <td>
                  <Button variant="secondary" size="sm" className="icon-btn" onClick={() => navigate(`/userdetailsoverview/${user.UserID}/${status}`)}>
                    <ViewIcon />
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      {listUsersWithToDoApiData && listUsersWithToDoApiData.Users && listUsersWithToDoApiData.Users.length > 0 && listUsersWithToDoApiData.TotalCount > PAGE_SIZE && (
        <Pagination
          pageSize={PAGE_SIZE}
          totalitems={listUsersWithToDoApiData.TotalCount}
          pageLimit={PAGE_SIZE}
          setCurrentPage={(page: number) => setCurrentPage(page)}
          currentPage={currentPage - 1}
          prevPage={-1}
          itemsDisplayed={listUsersWithToDoApiData?.Users?.length}
        />
      )}
    </>
  );
};

export default RadioGroup;
