/**
 * @file   src\components\Resources.tsx
 * @brief  404 page.
 * @date   Jan, 2024
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import '../../assets/css/Resource.scss';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Col, Tabs, Tab, Row } from 'react-bootstrap';
import Search from '../../components/MASearch';
import Slider from 'react-slick';
import Pagination from '../../components/Pagination';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Select from '../../components/MASelect';
import { slide as Filter } from 'react-burger-menu';
import { RootState } from '../../store';
import { useAppDispatch, useAppSelector } from '../../hooks';
import Close from '../../assets/img/Close.svg';
import useDebounce from '../../hooks/useDebounce';
import { useIntlActionMessages, useIntlMessages,getFromLocalStorage,isAdminTeacherManager,isUserAdmin,getCurrentOrgDetails} from '../../utils/helper';
import { getMotivationalList, getIncentiveResourceList, getReportedResourceList } from '../../store/actions/resourceActions';
import { ISelectOption, ISelectOptionsNumber, ISelectOptionsString } from '../../interfaces/GeneralInterface';
import { IOrgTypeResponse } from '../../interfaces/OrganizationTypeInterface';
import { PAGE_SIZE, DEFAULT_PAGE_INDEX, BUCKET_URL, PAGE_SIZE_12 } from '../../utils/constants';
import { RESOURCE_TABS,ResourcetypeID } from '../../utils/enums';
import Loader from '../../components/Loader';
import { getOrganizationTypeDropDownList, getOrganizationNameByOrgType, getCategoryDetailsById } from '../../store/actions/organizationTypeActions';
import { IResourceApiParams, IIncentiveApiParams, IReportedApiParams } from '../../interfaces/ResourceInterface';
import Default from '../../assets/img/resource_grey.png';
import MoreImg from '../../assets/img/more-image.jpg';

// Declare constants
const selectDays = [
  { label: 'Last 7 days', value: 'Last_7_days' },
  { label: 'Last 30 days', value: 'Last_30_days' },
  { label: 'Last 6 months', value: 'Last_6_month' },
  { label: 'Last 1 year', value: 'Last_1_year' },
];
const generalFilter = [
  { label: 'Most Used', value: 'Most_used' },
  { label: 'Newest', value: 'Newest' },
  { label: 'Completion Rate', value: 'Completion_rate' },
];
const contentType = [
  { label: 'Motivational Resources', value: 'motivational' },
  { label: 'Incentive Contents', value: 'incentive' },
];
// Declare default params for motivational resource
const defaultApiParams = {
  Page: DEFAULT_PAGE_INDEX,
  PageSize: PAGE_SIZE,
  OrganizationID: '',
  Search: '',
};
// Declare default params  for incentive contents
const defaultIncentiveApiParams: IIncentiveApiParams = {
  Filter: 'Newest',
  OrganizationID: '',
  IncentiveTitle: '',
  Page: DEFAULT_PAGE_INDEX,
  PageSize: PAGE_SIZE_12,
};
// Declare default params  for reported contents
const defaultReportedApiParams: IReportedApiParams = {
  DateRange: 'Last_7_days',
  Filter: [
    {
      CategoryId: 0,
      ResourceTypeId: 0,
      SubCategoryIDs: [0],
      OrganizationID: '',
    },
  ],
  Page: DEFAULT_PAGE_INDEX,
  PageSize: PAGE_SIZE_12,
  Search: '',
};

const Resources = () => {
  // Navigation object
  const navigate = useNavigate();
  // Create action dispatch object.
  const dispatch = useAppDispatch();
  // Location object
  const location = useLocation();
  // Initialize language variables.
  const OrganizationTypeText = useIntlActionMessages('Label.OrganizationType');
  const OrgNameText = useIntlActionMessages('Label.OrgName');
  const SelectText = useIntlMessages('PH.Select');
  const NodataText = useIntlActionMessages('Label.Nodata');

  // Access redux state variables.
  const {
    motivationalList,
    motivationalListApiLoading,
    incentiveResourceList,
    incentiveResourceListApiLoading,
    reportedResourceList,
    reportedResourceListApiLoading,
    reportedResourceListApiSuccess,
    incentiveResourceListApiSuccess,
  } = useAppSelector((state: RootState) => state.resource);
  const {
    organizationTypeDropDownData,
    organizationTypeDropDownSuccess,
    organizationTypeDropDownLoading,
    orgnameByOrgTypeData,
    orgnameByOrgTypeLoading,
    categoryDetailsByOrgIdLoading,
    categoryDetailsByOrgIdData,
  } = useAppSelector((state: RootState) => state.organizationType);
  // Slider settings
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  // Component state variables
  const [dateFilter, setDateFilter] = useState<any>(selectDays[0]);
  const [orgTypesValues, setOrgTypesValues] = useState<ISelectOptionsNumber[]>([]);
  const [organizationTypeValue, setOrganizationTypeValue] = useState<ISelectOption[]>([]);
  const [organizationTypeValueIncentive, setOrganizationTypeValueIncentive] = useState<ISelectOption[]>([]);
  const [organizationTypeValueFilter, setOrganizationTypeValueFilter] = useState<ISelectOption[]>([]);
  const [organizationNames, setOrganizationNames] = useState<ISelectOption[]>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<ISelectOption[]>([]);
  const [selectedOrganizationIncentive, setSelectedOrganizationIncentive] = useState<ISelectOption[]>([]);
  const [selectedOrganizationFilter, setSelectedOrganizationFilter] = useState<ISelectOption[]>([]);
  const [orgTypeId, setOrgTypeId] = useState<string>('');
  const [apiParams, setApiParams] = useState<IResourceApiParams>(defaultApiParams);
  const [searchText, setSearchText] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [tabKey, setTabKey] = useState<string>();
  const [generalFilterValue, setGeneralFilterValue] = useState<any>(generalFilter[1]);
  const [searchTextIncentive, setSearchTextIncentive] = useState<string>('');
  const [searchTextReported, setSearchTextReported] = useState<string>('');
  const [incentiveApiParams, setIncentiveApiParams] = useState<IIncentiveApiParams>(defaultIncentiveApiParams);
  const [reportedApiParams, setReportedApiParams] = useState<IReportedApiParams>(defaultReportedApiParams);
  const [currentPageIncentive, setCurrentPageIncentive] = useState<number>(1);
  // const [contentTypeValue, setContentTypeValue] = useState<ISelectOptionsString[]>([]);
  // const [selectedContentType, setSelectedContentType] = useState<string>();
  const [reportedResource, setReportedResource] = useState<boolean>(false);
  // const [category, setCategory] = useState<ISelectOptionsNumber[] | null>([]);
  // const [selectedCategory, setSelectedCategory] = useState<ISelectOption[]>([]);
  // const [selectedSubcategory, setSelectedSubcategory] = useState<ISelectOptionsString[]>([]);
  // const [subcategories, setSubcategories] = useState<ISelectOptionsString[]>([]);
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const [isFilterAppliedIncentive, setIsFilterAppliedIncentive] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedOrgnaizationID, setSelectedOrganizationID] = useState<string>('');
  const [selectedOrgnaizationIDIncentive, setSelectedOrganizationIDIncentive] = useState<string>('');

  const debouncedSearch = useDebounce(searchText, 500);
  const debouncedSearchIncentive = useDebounce(searchTextIncentive, 500);
  const debouncedSearchReported = useDebounce(searchTextReported, 500);

  // Works on initial rendering
  useEffect(() => {
    setTabKey(location?.state?.tabId ? RESOURCE_TABS[location?.state?.tabId] : RESOURCE_TABS[0]);
    dispatch(getOrganizationTypeDropDownList({}));
    window.scrollTo(0, 0);
  }, []);
  // Get organization names by organization type
  useEffect(() => {
    if (orgTypeId !== '') {
      dispatch(
        getOrganizationNameByOrgType({
          OrganizationTypeId: orgTypeId,
        }),
      );
    }
  }, [orgTypeId]);
  // Get organization details based roles
  useEffect(() => {
    const userData = getFromLocalStorage('MI_USR_DATA');
    const  OrganizationId= getCurrentOrgDetails() !== null ? getCurrentOrgDetails().OrganizationID : userData.OrganizationId;
    if (isAdminTeacherManager()) {
      setApiParams((prevApiParams) => ({
        ...prevApiParams,
        OrganizationID: OrganizationId,
      }));
      setIncentiveApiParams((incentiveApiParams) => ({
        ...incentiveApiParams,
        OrganizationID: OrganizationId,
      }));
    }
  }, [localStorage.getItem('CURRENT_ORG')]);
  // Fetch motivational list when dependencies changes and on intial loading
  useEffect(() => {
    if (tabKey === RESOURCE_TABS[0]) {
      dispatch(getMotivationalList(apiParams));
    } else if (tabKey === RESOURCE_TABS[1]) {
      dispatch(getIncentiveResourceList(incentiveApiParams));
    } else if (tabKey === RESOURCE_TABS[2]) {
      dispatch(getReportedResourceList(reportedApiParams));
    }
  }, [apiParams, tabKey, incentiveApiParams, reportedApiParams]);

  // Handle pagination
  useEffect(() => {
    setIncentiveApiParams((incentiveApiParams: any) => ({
      ...incentiveApiParams,
      Page: currentPageIncentive,
    }));
  }, [currentPageIncentive]);

  // Handle pagination
  useEffect(() => {
    setReportedApiParams((reportedApiParams: any) => ({
      ...reportedApiParams,
      Page: currentPage,
    }));
  }, [currentPage]);

  // Fetch organizations based on org type id
  useEffect(() => {
    if (organizationTypeDropDownSuccess) {
      const orgTypes: any = [];
      organizationTypeDropDownData.forEach((orgType: IOrgTypeResponse) =>
        orgTypes.push({
          label: orgType.orgTypeName,
          value: orgType.orgTypeId,
        }),
      );
      setOrgTypesValues(orgTypes);
      // setOrganizationTypeValueFilter(orgTypes[0]);
      // dispatch(
      //   getOrganizationNameByOrgType({
      //     OrganizationTypeId: orgTypes[0].value,
      //   }),
      // );
    }
  }, [organizationTypeDropDownLoading]);
  // Set organization names
  useEffect(() => {
    if (orgnameByOrgTypeData && orgnameByOrgTypeData.length > 0) {
      const organizationData = orgnameByOrgTypeData.map(
        (organization: any): ISelectOption => ({
          label: organization.Name,
          value: organization.ID,
        }),
      );
      setOrganizationNames(organizationData);
      // setSelectedOrganizationFilter(organizationData[0]);
      setSelectedOrganizationID(organizationData[0].value);
      dispatch(
        getCategoryDetailsById({
          OrganizationId: organizationData[0].value,
        }),
      );
    } else if (orgnameByOrgTypeData && orgnameByOrgTypeData.length === 0) {
      setOrganizationNames([]);
      setSelectedOrganization([]);
      setSelectedOrganizationFilter([]);
    }
  }, [orgnameByOrgTypeLoading]);
  // Supports debounced search Motivational
  useEffect(() => {
    setApiParams((apiParams: any) => ({
      ...apiParams,
      Search: searchText,
    }));
    setCurrentPage(DEFAULT_PAGE_INDEX);
  }, [debouncedSearch]);
  // Supports debounced search Incentive
  useEffect(() => {
    setIncentiveApiParams((incentiveApiParams: any) => ({
      ...incentiveApiParams,
      IncentiveTitle: searchTextIncentive,
      Page: DEFAULT_PAGE_INDEX,
    }));
    setCurrentPageIncentive(1);
  }, [debouncedSearchIncentive]);

  // Supports debounced search Reported
  useEffect(() => {
    setReportedApiParams((reportedApiParams: any) => ({
      ...reportedApiParams,
      Search: searchTextReported,
      Page: DEFAULT_PAGE_INDEX,
    }));
    setCurrentPage(1);
  }, [debouncedSearchReported]);

  // // Get category details by organization
  // useEffect(() => {
  //   if (categoryDetailsByOrgIdData && categoryDetailsByOrgIdData.length > 0) {
  //     const categoryData = categoryDetailsByOrgIdData.map(
  //       (category: any): ISelectOptionsNumber => ({
  //         label: category.CategoryName,
  //         value: category.CategoryId,
  //       }),
  //     );
  //     setCategory(categoryData);
  //   } else {
  //     // If ResponseData is null, update category state with null
  //     setCategory([]);
  //     setSelectedCategory([]);
  //   }
  // }, [categoryDetailsByOrgIdLoading]);

  // Handle search text change Reported
  const handleReportedSearchChange = (event: any) => {
    setSearchTextReported(event.target.value);
  };

  // Handle Search Field input key down.
  const handleReportedSearchKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setReportedApiParams((apiParams: any) => ({
        ...apiParams,
        Search: searchTextReported,
        Page: DEFAULT_PAGE_INDEX,
      }));
      setCurrentPage(DEFAULT_PAGE_INDEX);
    } else {
      setSearchTextReported(event.target.value);
    }
  };

  // Handle search button click Reported
  const handleReportedSearchClick = () => {
    setReportedApiParams({
      ...reportedApiParams,
      Search: searchTextReported,
      Page: DEFAULT_PAGE_INDEX,
    });
    setCurrentPage(1);
  };
  // Handle DateRange dropdown change
  const handleDateFilter = (event: any) => {
    setDateFilter([event]);
    setReportedApiParams({
      ...reportedApiParams,
      DateRange: event.value,
      Page: DEFAULT_PAGE_INDEX,
    });
  };
  // Handle search text change incentive
  const handleIncentiveSearchChange = (event: any) => {
    setSearchTextIncentive(event.target.value);
  };
  // Handle search button click incentive
  const handleIncentiveSearchClick = () => {
    setIncentiveApiParams({
      ...incentiveApiParams,
      IncentiveTitle: searchTextIncentive,
      Page: DEFAULT_PAGE_INDEX,
    });
    setCurrentPageIncentive(1);
  };
  // Handle organization type change
  const handleOrganizationTypeChange = (event: ISelectOption) => {
    setOrganizationTypeValue([event]);
    if (tabKey === RESOURCE_TABS[2]) {
      setOrganizationTypeValueFilter([event]);
    } else if (tabKey === RESOURCE_TABS[1]) {
      setOrganizationTypeValueIncentive([event]);
    }
    setOrgTypeId(event.value);
    setSelectedOrganization([]);
    setSelectedOrganizationFilter([]);
    setSelectedOrganizationIncentive([]);
    setApiParams({ ...apiParams, OrganizationID: '' });
    dispatch(
      getOrganizationNameByOrgType({
        OrganizationTypeId: event.value,
      }),
    );
  };
  // Handle organization change
  const handleOrganizationChange = (event: ISelectOption) => {
    setApiParams({ ...apiParams, OrganizationID: event.value });
    setSelectedOrganization([event]);
    if (tabKey === RESOURCE_TABS[2]) {
      setSelectedOrganizationFilter([event]);
    } else if (tabKey === RESOURCE_TABS[1]) {
      setSelectedOrganizationIncentive([event]);
      setSelectedOrganizationIDIncentive(event.value);
    }
    setSelectedOrganizationID(event.value);
    if (reportedResource) {
      dispatch(
        getCategoryDetailsById({
          OrganizationId: event.value,
        }),
      );
    }
  };
  // Handle organization change
  const handleOrganizationChangeIncentive = (event: ISelectOption) => {
    setSelectedOrganizationIncentive([event]);
    setSelectedOrganizationIDIncentive(event.value);
  };
  // Reset values when tabs change
  const resetValues = (key: string) => {
    if (key === RESOURCE_TABS[0] || key == null) {
      setSearchText('');
      setOrganizationTypeValue([]);
      setOrganizationTypeValueFilter([]);
      setSelectedOrganization([]);
      setSelectedOrganizationFilter([]);
      setSelectedOrganizationID('');
      setApiParams({ ...apiParams, OrganizationID: '' });
    }
    setTabKey(key ? key : RESOURCE_TABS[0]);
  };
  // Handle dropdown change
  const handleGeneralFilterChange = (event: any) => {
    setGeneralFilterValue([event]);
    setIncentiveApiParams({
      ...incentiveApiParams,
      Filter: event.value,
      Page: DEFAULT_PAGE_INDEX,
    });
  };
  // // Handle content type change
  // const handleContentTypeChange = (event: ISelectOptionsString) => {
  //   setContentTypeValue([event]);
  //   setSelectedContentType(event.value.toString());
  //   setReportedResource(true);
  // };
  // Get subcategories based on category
  // const handleCategoryChange = async (event: any) => {
  //   const actualCategoryId = event.value;
  //   setSelectedCategory([event]);
  //   const category = categoryDetailsByOrgIdData.find((category: any) => category.CategoryId === actualCategoryId);
  //   if (category) {
  //     const subcategories = category.Subcategory.map((subcategory: any) => ({
  //       label: subcategory.classname,
  //       value: subcategory.classid,
  //     }));
  //     setSubcategories(subcategories);
  //     setSelectedSubcategory([]);
  //   } else {
  //     setSubcategories([]);
  //   }
  // };
  // Handle class chanage
  // const changeSubCategory = (event: any) => {
  //   setSelectedSubcategory(event);
  // };
  // Handle apply filter reported tab
  const handleFilterChange = () => {
    setIsFilterApplied(true);
    setReportedApiParams((reportedApiParams: any) => ({
      ...reportedApiParams,
      Filter: [
        {
          // CategoryId: parseInt(selectedCategory[0]?.value),
          // ResourceTypeId: selectedContentType == 'motivational' ? 1 : 2,
          // SubCategoryIDs: selectedSubcategory.map((item) => item.value),
          OrganizationID: selectedOrgnaizationID,
        },
      ],
      Page: DEFAULT_PAGE_INDEX,
    }));
    setCurrentPage(1);
    setOpen(false);
  };
  // Handle reset filter reported tab
  const handleFilterReset = () => {
    setIsFilterApplied(false);
    setReportedApiParams((reportedApiParams: any) => ({
      ...reportedApiParams,
      Filter: [
        {
          CategoryId: 0,
          ResourceTypeId: 0,
          SubCategoryIDs: [0],
          OrganizationID: '',
        },
      ],
      Page: DEFAULT_PAGE_INDEX,
    }));

    setCurrentPage(1);
    setOrganizationTypeValue([]);
    setOrganizationTypeValueFilter([]);
    setSelectedOrganization([]);
    setSelectedOrganizationFilter([]);
    // setContentTypeValue([]);
    // setSelectedContentType('');
    // setSelectedCategory([]);
    // setSelectedSubcategory([]);
    setOpen(false);
  };

  // Handle apply filter incentive tab
  const handleFilterChangeIncentive = () => {
    setIsFilterAppliedIncentive(true);
    setIncentiveApiParams({
      ...incentiveApiParams,
      OrganizationID: selectedOrgnaizationIDIncentive,
      Page: DEFAULT_PAGE_INDEX,
    });
    setCurrentPageIncentive(1);
    setOpen(false);
  };
  // Handle reset filter incentive tab
  const handleFilterResetIncentive = () => {
    setIsFilterAppliedIncentive(false);
    setIncentiveApiParams({
      ...incentiveApiParams,
      OrganizationID: '',
      Page: DEFAULT_PAGE_INDEX,
    });

    setCurrentPageIncentive(1);
    setOrganizationTypeValueIncentive([]);
    setSelectedOrganizationIncentive([]);
    setSelectedOrganizationIDIncentive('');
    setOpen(false);
  };

  return (
    <>
      <div className="resources-wrap">
        <div className="page-title orgAdd-page-title d-flex justify-content-between align-items-center">
          <h3>
            <FormattedMessage id="Hd.Resources" />
          </h3>
        </div>
        <Tabs
          defaultActiveKey={location?.state?.tabId ? RESOURCE_TABS[location?.state?.tabId] : tabKey}
          id="uncontrolled-tab-example"
          className="mb-3 d-flex justify-content-center"
          onSelect={(key: any) => resetValues(key)}
        >
          <Tab eventKey={RESOURCE_TABS[0]} title={useIntlActionMessages('Hd.MotivationalResources')}>
            <div className="content-sub mb-3 p-3">
              <Row className="d-flex align-items-center justify-content-between">
                <Col xl={3} lg={12} md={12} className="form-sm mb-1"></Col>
                <Col xl={7}>
                  <Row className="align-items-end">
                    <Col className="form-sm">
                      {isUserAdmin() && (
                        <Select
                          options={orgTypesValues}
                          value={organizationTypeValue}
                          placeholder={useIntlMessages('PH.Select')}
                          onChange={(e: any) => handleOrganizationTypeChange(e)}
                          label={useIntlActionMessages('Label.OrgType')}
                        />
                      )}
                    </Col>
                    <Col className="form-sm">
                      {isUserAdmin() && (
                        <Select
                          label={OrgNameText}
                          options={organizationNames}
                          placeholder={SelectText}
                          onChange={(e: any) => handleOrganizationChange(e)}
                          value={selectedOrganization}
                          isDisabled={orgTypeId === ''}
                        />
                      )}
                    </Col>

                    <Col sm="auto">
                      <Button variant="primary" size="sm" className="res-addMot-btn" onClick={() => navigate('/addmotivation')}>
                        <FormattedMessage id="Button.AddMotivationalResource" />
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className="content-sub p-3">
              {motivationalList && motivationalList?.Resources?.length > 0 ? (
                motivationalList.Resources.map((category: any) => (
                  <div key={category.Title}>
                    <div className="d-flex justify-content-between pb-2 mt-4">
                      <h4>{category.Title}</h4>
                      <Link
                        to="/resourcesViewall"
                        state={{ tabId: 0, titleId: category.TitleId, title: category.Title, OrganizationID: selectedOrganization }}
                        className="text-center view-all-btn"
                      >
                        <FormattedMessage id="Link.ViewAll" />
                      </Link>
                    </div>
                    <div className="res-slider">
                      <Slider {...settings}>
                        {category.Data.map((item: any, index: number) => {
                          if (index < 9) {
                            return (
                              <div key={item.Key} className="slide-box">
                                <img
                                  src={item && item.File ? BUCKET_URL + item.File : Default}
                                  alt={item.FileName}
                                  onClick={() =>
                                    navigate(`/resourceDetails/${ResourcetypeID.MOTIVATIONAL_RESOURCE}/${item.Key}`, {
                                      state: {
                                        tabId: 0,
                                      },
                                    })
                                  }
                                />
                                <div className="slid-name">
                                  <p className="text-capitalize">{item.FileName}</p>
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div key="default" className="slide-box">
                                <img
                                  src={MoreImg}
                                  onClick={() =>
                                    navigate('/resourcesViewall', {
                                      state: {
                                        tabId: 0,
                                        titleId: category.TitleId,
                                        title: category.Title,
                                        OrganizationID: selectedOrganization,
                                      },
                                    })
                                  }
                                />
                                <div className="slid-name">
                                  <p>
                                    <FormattedMessage id="Link.ViewAll" />
                                  </p>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </Slider>
                    </div>
                  </div>
                ))
              ) : (
                <div className="content-sub content-area-padding border-top text-center">
                  <h6>{NodataText}</h6>
                </div>
              )}
            </div>
          </Tab>
          <Tab eventKey={RESOURCE_TABS[1]} title="Incentive Contents ">
            <div className="content-sub mb-3 p-3">
              <Row className="d-flex align-items-center">
                <Col xl={4} lg={12} md={12} className="form-sm mb-1">
                  <Search
                    placeholder={useIntlActionMessages('Label.SearchResource')}
                    size="sm"
                    onChange={handleIncentiveSearchChange}
                    onClick={handleIncentiveSearchClick}
                    value={searchTextIncentive ? searchTextIncentive : ''}
                  />
                </Col>
                <Col md={12} xl={8} lg={12} className="d-flex justify-content-end mb-1">
                  <Col className="d-flex align-items-center justify-content-end ">
                  {isUserAdmin() && (
                    <Filter
                      isOpen={open}
                      onOpen={() => {
                        setOpen(true);
                      }}
                      onClose={() => setOpen(false)}
                      width={450}
                      right
                      pageWrapId={'filter-wrapper'}
                      outerContainerId={'outer-container'}
                      customCrossIcon={<img src={Close} onClick={() => setOpen(false)} />}
                      burgerButtonClassName={isFilterAppliedIncentive ? 'active' : ''}
                    >
                      <div id="filter-wrapper" className="filter-main ">
                        <h4 className="mb-5">{useIntlActionMessages('Hd.FilterIncentiveContents')}</h4>
                        <Col className="form-sm">
                          <Select
                            ID={useIntlActionMessages('Label.OrgType')}
                            options={orgTypesValues}
                            value={organizationTypeValueIncentive}
                            placeholder={useIntlMessages('PH.Select')}
                            onChange={(e: any) => handleOrganizationTypeChange(e)}
                            label={useIntlActionMessages('Label.OrgType')}
                          />
                        </Col>
                        <Col className="form-sm">
                          <Select
                            ID={useIntlActionMessages('Label.OrgName')}
                            label={OrgNameText}
                            options={organizationNames}
                            placeholder={SelectText}
                            onChange={(e: any) => handleOrganizationChangeIncentive(e)}
                            value={selectedOrganizationIncentive}
                            isDisabled={orgTypeId === ''}
                          />
                        </Col>
                        <Row className="mt-4">
                          <Col>
                            <Button variant="outline-primary" className="w-100" onClick={handleFilterResetIncentive}>
                              {useIntlActionMessages('Button.ClearFilter')}
                            </Button>
                          </Col>
                          <Col>
                            <Button variant="primary" className="w-100" onClick={handleFilterChangeIncentive}>
                              {useIntlActionMessages('Button.ApplyFilters')}
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Filter>
                  )}
                    <Col xl={4} lg={4} md={4} className="form-sm mx-2">
                      <Select options={generalFilter} placeholder="Most Used" onChange={(e: any) => handleGeneralFilterChange(e)} value={generalFilterValue} />
                    </Col>
                  </Col>
                  <Button variant="primary" size="sm" className="res-addMot-btn" onClick={() => navigate('/addincentivecontent')}>
                    <FormattedMessage id="Button.AddIncentiveContents" />
                  </Button>
                </Col>
              </Row>
            </div>
            <div className="content-sub content-sub-thumb mb-3 p-3">
              <Row>
                {incentiveResourceList && incentiveResourceList?.IncentiveContents?.length > 0 ? (
                  incentiveResourceList.IncentiveContents.map((item: any, index: number) => (
                    <Col md={3} className="mb-3" key={index}>
                      <div key={item.ContentId} className="thum-box">
                        <img
                          src={item.Image ? BUCKET_URL + item.Image : Default}
                          alt="no image"
                          onClick={() =>
                            navigate(`/resourceDetails/${ResourcetypeID.INCENTIVE_CONTENT}/${item.ContentId}`, {
                              state: {
                                tabId: 1,
                              },
                            })
                          }
                        />
                        <div className="slid-name">
                          <p className="text-capitalize">{item.Title}</p>
                        </div>
                      </div>
                    </Col>
                  ))
                ) : (
                  <div className="content-sub content-area-padding border-top text-center">
                    <h6>{NodataText}</h6>
                  </div>
                )}
              </Row>
            </div>
            {incentiveResourceListApiSuccess && incentiveResourceList?.IncentiveContents?.length > 0 && incentiveResourceList?.TotalCount > PAGE_SIZE_12 && (
              <Pagination
                pageSize={PAGE_SIZE_12}
                totalitems={incentiveResourceList?.TotalCount}
                pageLimit={PAGE_SIZE_12}
                setCurrentPage={(page: number) => setCurrentPageIncentive(page)}
                currentPage={currentPageIncentive - 1}
                prevPage={-1}
                itemsDisplayed={incentiveResourceList?.IncentiveContents?.length}
              />
            )}
          </Tab>
          {isUserAdmin() ? (
            <Tab eventKey={RESOURCE_TABS[2]} title="Reported Contents">
              <div className="content-sub mb-3 p-3">
                <Row className="d-flex">
                  <Col md={4} className="form-sm">
                    <Search
                      placeholder={useIntlActionMessages('Label.SearchResource')}
                      size="sm"
                      onChange={handleReportedSearchChange}
                      onKeyDown={handleReportedSearchKeyDown}
                      onClick={handleReportedSearchClick}
                      value={searchTextReported ? searchTextReported : ''}
                    />
                  </Col>
                  <Col md={{ span: 4, offset: 4 }} className="form-sm d-flex justify-content-end">
                    <Filter
                      isOpen={open}
                      onOpen={() => {
                        setOpen(true);
                      }}
                      onClose={() => setOpen(false)}
                      width={450}
                      right
                      pageWrapId={'filter-wrapper'}
                      outerContainerId={'outer-container'}
                      customCrossIcon={<img src={Close} onClick={() => setOpen(false)} />}
                      burgerButtonClassName={isFilterApplied ? 'active' : ''}
                    >
                      <div id="filter-wrapper" className="filter-main ">
                        <h4 className="mb-5">{useIntlActionMessages('Hd.FilterReportedContents')}</h4>

                        {/* <Row className="align-items-end"> */}
                        <Col className="form-sm">
                          <Select
                            options={orgTypesValues}
                            value={organizationTypeValueFilter}
                            placeholder={useIntlMessages('PH.Select')}
                            onChange={(e: any) => handleOrganizationTypeChange(e)}
                            label={OrganizationTypeText}
                          />
                        </Col>
                        <Col className="form-sm">
                          <Select
                            label={OrgNameText}
                            options={organizationNames}
                            placeholder={SelectText}
                            onChange={(e: any) => handleOrganizationChange(e)}
                            value={selectedOrganizationFilter}
                            isDisabled={orgTypeId === ''}
                          />
                        </Col>
                        {/* </Row> */}

                        <Row className="mt-4">
                          <Col>
                            <Button variant="outline-primary" className="w-100" onClick={handleFilterReset}>
                              {useIntlActionMessages('Button.ClearFilter')}
                            </Button>
                          </Col>
                          <Col>
                            <Button variant="primary" className="w-100" onClick={handleFilterChange}>
                              {useIntlActionMessages('Button.ApplyFilters')}
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Filter>
                    <Select className="select-res-rep" options={selectDays} value={dateFilter} placeholder="Latest" onChange={(event: any) => handleDateFilter(event)} />
                  </Col>
                </Row>
              </div>
              <div className="content-sub content-sub-thumb mb-3 p-3">
                <Row>
                  {reportedResourceList && reportedResourceList?.Resources?.length > 0 ? (
                    reportedResourceList?.Resources?.map((item: any, index: number) => (
                      <Col md={3} className="mb-3" key={index}>
                        <div className="thum-box">
                          <img
                            src={item.File ? BUCKET_URL + item.File : Default}
                            alt="no image"
                            onClick={() =>
                              navigate(`/resourceDetails/${item.Key}`, {
                                state: {
                                  tabId: 2,
                                },
                              })
                            }
                          />
                          <div className="slid-name">
                            <p className="text-capitalize">{item.FileName}</p>
                          </div>
                        </div>
                      </Col>
                    ))
                  ) : (
                    <div className="content-sub content-area-padding border-top text-center">
                      <h6>{NodataText}</h6>
                    </div>
                  )}
                </Row>
              </div>
              {reportedResourceListApiSuccess && reportedResourceList?.Resources?.length > 0 && reportedResourceList?.TotalCount > PAGE_SIZE && (
                <Pagination
                  pageSize={PAGE_SIZE_12}
                  totalitems={reportedResourceList?.TotalCount}
                  pageLimit={PAGE_SIZE_12}
                  setCurrentPage={(page: number) => setCurrentPage(page)}
                  currentPage={currentPage - 1}
                  prevPage={-1}
                  itemsDisplayed={reportedResourceList?.Resources?.length}
                />
              )}
            </Tab>
          ) : null}
        </Tabs>
        {(motivationalListApiLoading || incentiveResourceListApiLoading || reportedResourceListApiLoading || categoryDetailsByOrgIdLoading) && <Loader />}
      </div>
    </>
  );
};
export default Resources;
