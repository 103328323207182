/**
 * @file   src\utils\enums.ts
 * @brief  This file is for defining enums.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
// Routes enum
export const enum Paths {
  TEACHER_HOME = '/home',
  ADMIN_HOME = '/home',
  NOTIFICATIONS = '/notification',
  TEACHER_ACTIVITIES = '/activities',
  USERS = '/manageuser',
  RESOURCES = '/resources',
  COMPETENCIES = '/competencies',
  MANAGE_ORGANISATIONS = '/manageorganization',
  MANAGE_SETTINGS = '/managesettings',
  SUBSCRIPTION_PLANS = '/subscriptionplans',
}

// Mirrorapp has 7 levels of users. this enum has the role type ids of users.this is fixed
export const enum RoleTypeIds {
  SUPER_ADMIN = 1,
  ORGANIZATION_ADMIN = 2,
  ORGANIZATION_MANGER = 3,
  APP_USER_MANAGER = 4,
  ACCOUNTABILITY_PARTNER = 5,
  APP_USER = 6,
  SYSTEM_ADMIN = 7,
}

// Sort order type enum.
export enum SortOrderType {
  ASC = 'asc',
  DESC = 'desc',
}

// OrganizationType sort fields enum.
export enum OrganizationTypeSortField {
  ORG_TYPE_ID = 'organizationtypeid',
  ORG_TYPE_NAME = 'organizationtype',
  ORG_TYPE_WEBSITE_USERS = 'NumberofWebsiteUsers',
  ORG_TYPE_APP_USERS = 'NumberofAppUsers',
  ORG_TYPE_ORGANIZATIONS = 'NumberofOrganizations',
}

// Organizations sort fields enum.
export enum OrganizationsSortField {
  ORG_NAME = 'OrganizationName',
  ORG_WEBSITE_USERS = 'NumberofWebsiteUsers',
  ORG_APP_USERS = 'NumberofAppUsers',
  ORG_SUBSCRIPTION_PLAN = 'SubscriptionPlan',
}
// file upload maximum sizes enum
export enum UploaderProps {
  IMAGE_MAX_SIZE = 250000000, // 250MB
  AUDIO_MAX_SIZE = 250000000,
  VIDEO_MAX_SIZE = 250000000,
  DOCUMENT_MAX_SIZE = 250000000,
}
// 1073741824  1GB
// 250000000  250MB
// 50000000 50MB
export const IMAGE_TYPES_SUPPORTED = {
  'image/png': ['.png'],
  'image/jpg': ['.jpg', '.jpeg'],
};
//   export const FILE_TYPES_SUPPORTED = {
//     'image': ['png','jpg', 'jpeg'],
//     'audio': ['mp3'],
//     'video': ['mp4','webm'],
//   };
export const FILE_TYPES_SUPPORTED = {
  image: ['png', 'jpg', 'jpeg'],
  video: ['mp4', 'avi', 'mkv', 'mov', 'webm'],
  audio: ['mp3', 'wav', 'mp2', 'm4a'],
  document: ['docx', 'pdf', 'txt', 'doc', 'xls', 'xlsx', 'ppt', 'pptx'],
};
// User list sort fields enum
export enum UsersSortFields {
  USERID = 'UserId',
  NAME = 'Name',
  EMAIL = 'Email',
  ORG_NAME = 'OrganizationName',
  DOJ = 'DOJ',
  USERNAME = 'UserName',
  NO_OF_USERS = 'NoAssignedUser',
  PHONE = 'Phone',
}
// Sort type enum
export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}
export enum NumberValues {
  NUM_1 = 1,
  NUM_4 = 4,
  NUM_5 = 5,
  NUM_6 = 6,
  NUM_10 = 10,
  NUM_13 = 13,
  NUM_20 = 20,
  NUM_100 = 100,
  NUM_150 = 150,
  NUM_200 = 200,
  NUM_250 = 250,
  NUM_1000 = 1000,
  NUM_3 = 3,
}
// Goal type enum
export enum GoalType {
  Big_Goal = 1,
  Yearly_Goal = 2,
  Term_Goal = 3,
  Quarterly = 4,
  Monthly = 5,
  Weekly = 6,
  Daily = 7,
  Hourly = 8,
}
// Goal priority level enum
export enum Priority {
  High = 1,
  Medium = 2,
  Low = 3,
}
// Mirrorapp has 2 resource types
export const enum ResourcetypeID {
  MOTIVATIONAL_RESOURCE = 1,
  INCENTIVE_CONTENT = 2,
}
export const RESOURCE_TABS = ['MotivationalResources', 'IncentiveResources', 'ReportedResources'];
export const ASSIGNMENT_STUDENTLIST_TABS = ['Completed', 'Inprogress', 'NotStarted'];
// Task and event types enum.
export enum AssignmentType {
  TASK = 'task',
  EVENT = 'event',
}

// Goal sort fields enum.
export enum GoalSortField {
  GOAL_TYPE = 'type',
  GOAL_NAME = 'name',
  CATEGORY = 'category',
  SUB_CATEGORY = 'subcategory',
  CREATED_BY = 'createdby',
}
// Task and event types enum.
export enum TaskEventContext {
  TASK = 'Task',
  EVENT = 'Event',
}
// Goal tasks/events sort fields enum.
export enum GoalTaskEventListSortField {
  NAME = 'name',
  CATEGORY = 'category',
  CREATED_BY = 'createdby',
}
// Goal assignment type enum.
export enum AssignmentType {
  IMAGE = 1,
  AUDIO = 2,
  VIDEO = 3,
  DOCUMENT = 4,
}
// Country codes enum
export enum CountryCodes {
  US = '+1',
  IND = '+91',
}
// Gender values enum
export enum GenderValues {
  MALE = 'M',
  FEMALE = 'F',
  TRANS = 'O',
}
// Survey sort fields enum.
export enum SurveySortField {
  SURVEY_ID = 'SurveyId',
  SURVEY_NAME = 'SurveyName',
  CATEGORY = 'Category',
  CREATED_BY = 'CreatedBy',
  ASSIGNEDTO = 'AssignedTo',
}

// Subscription plan sort fields enum.
export enum SubscriptionPlanSortField {
  TYPE_NAME = 'OrganizationType',
  ORG_NAME = 'OrganizationName',
  PLAN_START_DATE = 'SubscriptionStartDate',
  PLAN_END_DATE = 'SubscriptionEndDate',
}
// Subscription plan type enum
export enum PlanTypes {
  YEARLY = 'Yearly',
  HALF_YEARLY = 'Half yearly',
  MONTHLY = 'Monthly',
  QUARTERLY = 'Quarterly',
}
// Profile Column enum
export enum ProfileColumn {
  Gender = 1,
  DOB = 2,
  HighestEducation = 3,
  Address = 4,
  City = 5,
  State = 6,
  Zip = 7,
}
// Review proof page sort enum
export enum ReviewProofSort {
  STUDENT_NAME = 1,
  TASK = 2,
}
// Review proff status enum
export enum ReviewProofStatus {
  COMPLETED = 1,
  IN_PROGRESS = 2,
}

// Overview list sort fields enum.
export enum OverviewListSortField {
  NAME = 'Name',
  JOINING_DATE = 'JoiningDate',
}